import { faGameConsoleHandheld } from '@fortawesome/pro-light-svg-icons';


let tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
if(tooltips.length) {
    import(/* webpackChunkName: "bs-tooltip" */ 'bootstrap/js/dist/tooltip')
    .then((Tooltip) => {
        for(var i = 0; i < tooltips.length; i++)
        {
            console.log('loaded bs tooltips')
            let tooltip = tooltips.item(i);
			let tool = new Tooltip.default(tooltip);

			tooltip.addEventListener('shown.bs.tooltip', function () {
				if(tool._config.trigger === 'click') {
					setTimeout(() => {
						tool.hide();
					}, 500)
				}
			})
        } 
    });
}

let canvas = document.querySelectorAll('[data-bs-toggle="offcanvas"]');
if(canvas.length) {
    import(/* webpackChunkName: "bs-canvas" */ 'bootstrap/js/dist/offcanvas')
    .then(() => {
        console.log('loaded bs canvas');
    });
}
let modals = document.querySelectorAll('[data-bs-toggle="modal"]');
if(modals.length) {
    import(/* webpackChunkName: "bs-modal" */ 'bootstrap/js/dist/modal')
    .then(() => {
        console.log('loaded bs modal')
    });
}

let dropdown = document.querySelectorAll('[data-bs-toggle="dropdown"]');
if(dropdown.length) {
    import(/* webpackChunkName: "bs-dropdown" */ 'bootstrap/js/dist/dropdown')
    .then(() => {
        console.log('loaded bs dropdown')
    })
}

let tabs = document.querySelectorAll('[data-bs-toggle="tab"]');
if(tabs.length) {
    import(/* webpackChunkName: "bs-tab" */ 'bootstrap/js/dist/tab')
    .then(() => {
        console.log('loaded bs tabs')
    })
}

let alerts = document.querySelectorAll('[data-dismiss="alert"]');
if(alerts.length) {
    import(/* webpackChunkName: "bs-alert" */ 'bootstrap/js/dist/alert')
    .then(() => {
        console.log('alerts loaded')
    })
}

let collapses = document.querySelectorAll('.collapse');
if(collapses.length) {
    import(/* webpackChunkName: "bs-collapse" */ 'bootstrap/js/dist/collapse')
    .then(() => {
        console.log('collapse loaded')
    })
}

let toasts = document.querySelectorAll('.toast');
if(toasts.length) {
    import(/* webpackChunkName: "bs-toast" */ 'bootstrap/js/dist/toast')
    .then((Toast) => {
        for(var i = 0; i < toasts.length; i++)
        {
            let el = toasts.item(i);
			let toast = new Toast.default(el, {
				autohide: false
			});
            toast.show();
        } 
    })
}

let carousel = document.querySelectorAll('.carousel');
if(carousel.length) {
    import(/* webpackChunkName: "bs-carousel" */ 'bootstrap/js/dist/carousel')
    .then(() => {
        console.log('carousel loaded')
    })
}

// let datepicker = document.querySelectorAll('.datepicker');
// if(datepicker.length) {
//     import(/* webpackChunkName: "bs-datepicker" */ '../../node_modules/bootstrap-datepicker/dist/js/bootstrap-datepicker')
//     .then(() => {
//         console.log('datepicker loaded')
//     })
// }
